@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
.section1 {
  position: relative;
  width: calc(100vw * (1920 / 1920));
  height: calc(100vw * (600 / 1920));
  /* border: solid; */
  /* overflow: hidden; */
}
.section1Header {
  position: relative;
  z-index: 3;
}
.circle1 {
  position: absolute;
  width: calc(100vw * (1436 / 1920));
  height: calc(100vw * (1432 / 1920));
  top: calc(100vw * (-824 / 1920));
  left: calc(100vw * (-832 / 1920));
  z-index: 1;
}
.circle2 {
  position: absolute;
  width: calc(100vw * (1658 / 1920));
  height: calc(100vw * (1653 / 1920));
  top: calc(100vw * (-854 / 1920));
  left: calc(100vw * (1050 / 1920));
  z-index: 1;
}
.circle3 {
  position: absolute;
  width: calc(100vw * (1158 / 1920));
  height: calc(100vw * (1155 / 1920));
  top: calc(100vw * (-604 / 1920));
  left: calc(100vw * (1331 / 1920));
  z-index: 1;
}

.center {
  margin-top: calc(100vw * (87 / 1920));
  margin-bottom: calc(100vw * (110 / 1920));
  margin-left: calc(100vw * (622 / 1920));
  margin-right: calc(100vw * (596 / 1920));
  display: flex;
  width: calc(100vw * (702 / 1920));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: calc(100vw * (20 / 1920));
}
.center .title {
  font-size: calc(100vw * (75 / 1920));
  color: #242331;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}
.center .description {
  font-size: calc(100vw * (25 / 1920));
  color: #797979;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  line-height: 157.4%;
}

@media (max-width: 500px) {
  .section1 {
    position: relative;
    height: calc(100vw * (244 / 395));
  }
  .circle1 {
    position: absolute;
    width: calc(100vw * (1436 / 1920));
    height: calc(100vw * (1432 / 1920));
    top: calc(100vw * (-824 / 1920));
    left: calc(100vw * (-832 / 1920));
    z-index: 1;
  }
  .circle2 {
    position: absolute;
    width: calc(100vw * (1658 / 1920));
    height: calc(100vw * (1653 / 1920));
    top: calc(100vw * (-854 / 1920));
    left: calc(100vw * (1050 / 1920));
    z-index: 1;
  }
  .circle3 {
    position: absolute;
    width: calc(100vw * (1158 / 1920));
    height: calc(100vw * (1155 / 1920));
    top: calc(100vw * (-604 / 1920));
    left: calc(100vw * (1331 / 1920));
    z-index: 1;
  }
  
  .center {
    margin-top: calc(100vw * (40 / 395));
    margin-bottom: calc(100vw * (40 / 395));
    margin-left: calc(100vw * (47 / 395));
    margin-right: calc(100vw * (131 / 395));
    width: calc(100vw * (297 / 395));
    gap: calc(100vw * (10 / 395));
  }
  .center .title {
    font-size: calc(100vw * (16 / 395));
  }
  .center .description {
    font-size: calc(100vw * (10 / 395));
  }
}
