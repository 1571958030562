.section1{
    position: relative;
    width: calc(100vw * (1920 / 1920));
    height: calc(100vw * (959 / 1920));
    background-image: url("../../../../assets/hero1.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
}
.section1Header{
    position: relative;
    z-index: 3;
}
.circle1{
    position: absolute;
    width: calc(100vw * (1059 / 1920));
    height: calc(100vw * (1056 / 1920));
    top: calc(100vw * (-326 / 1920));
    left: calc(100vw * (-72 / 1920));
    z-index: 1;
}
.circle2{
    position: absolute;
    width: calc(100vw * (1222 / 1920));
    height: calc(100vw * (1219 / 1920));
    top: calc(100vw * (-174 / 1920));
    left: calc(100vw * (1024 / 1920));
    z-index: 1;
}
.circle3{
    position: absolute;
    width: calc(100vw * (854 / 1920));
    height: calc(100vw * (851 / 1920));
    top: calc(100vw * (-5.17 / 1920));
    left: calc(100vw * (1228 / 1920));
    z-index: 1;
}
.cardDiv{
    position: absolute;
    top: calc(100vw * (250 / 1920));
    left: calc(100vw * (155 / 1920));
}

@media (max-width: 500px) {
    .section1{
        position: relative;
        /* width: calc(100vw * (1920 / 1920)); */
        height: calc(100vw * (244 / 395));
    }
}