.switchContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(100vw * (10 / 1920));
  }

  .priceLabel {
    /* font-size: 14px; */
    transition: color 0.3s, font-weight 0.3s;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: calc(100vw * (60 / 1920));
    height: calc(100vw * (34 / 1920));
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: calc(100vw * (26 / 1920));
    width: calc(100vw * (26 / 1920));
    left: calc(100vw * (4 / 1920));
    bottom: calc(100vw * (4 / 1920));
    background-color: white;
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: #2196f3;
  }
  
  input:checked + .slider:before {
    transform: translateX(calc(100vw * (26 / 1920)));
  }
  
  .round {
    border-radius: calc(100vw * (34 / 1920));
  }
  
  .round:before {
    border-radius: 50%;
  }

  @media (max-width: 500px) {
    .switchContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: calc(100vw * (5 / 395));
      }
      
      .switch {
        position: relative;
        display: inline-block;
        width: calc(100vw * (30 / 395));
        height: calc(100vw * (17 / 395));
      }
      
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.4s;
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: calc(100vw * (13 / 395));
        width: calc(100vw * (13 / 395));
        left: calc(100vw * (2 / 395));
        bottom: calc(100vw * (2 / 395));
        background-color: white;
        transition: 0.4s;
      }
      
      input:checked + .slider {
        background-color: #2196f3;
      }
      
      input:checked + .slider:before {
        transform: translateX(calc(100vw * (13 / 395)));
      }
      
      .round {
        border-radius: calc(100vw * (17 / 395));
      }
      
      .round:before {
        border-radius: 50%;
      }
  }