@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
.section1Card {
  width: calc(100vw * (770 / 1920));
  height: calc(100vw * (544 / 1920));
  border-radius: 40px;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: calc(100vw * (105 / 1920));
  padding-top: calc(100vw * (43 / 1920));
  padding-bottom: calc(100vw * (64 / 1920));
}
.title1,
.title2 {
  width: calc(100vw * (610 / 1920));
  height: calc(100vw * (84 / 1920));
  display: flex;
  align-items: center;
  color: #242331;
  font-family: Nunito;
  font-size: calc(100vw * (75 / 1920));
  font-style: normal;
  font-weight: 800;
  /* line-height: 110%; */
}
.desc {
  width: calc(100vw * (606 / 1920));
  height: calc(100vw * (105 / 1920));
  margin-top: calc(100vw * (31 / 1920));
  margin-bottom: calc(100vw * (60 / 1920));
  color: #797979;
  font-family: Nunito;
  font-size: calc(100vw * (25 / 1920));
  font-style: normal;
  font-weight: 400;
  /* line-height: 157.4%; */
}
button {
  position: relative;
  z-index: 5;
  display: flex;
  padding: 25px 51px;
  padding-top: calc(100vw * (25 / 1920));
  padding-bottom: calc(100vw * (25 / 1920));
  padding-left: calc(100vw * (51 / 1920));
  padding-right: calc(100vw * (51 / 1920));
  justify-content: center;
  align-items: center;
  border-radius: 39px;
  background: #6c5dd3;
  color: #f9f9f9;
  font-family: Nunito;
  font-size: calc(100vw * (18 / 1920));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  transition: transform 0.3s ease;
}
button:hover{
  transform: scale(0.9);
}

@media (max-width: 500px) {
  .section1Card {
    width: calc(100vw * (158 / 395));
    height: calc(100vw * (172 / 395));
    border-radius: 12px;
    padding-left: calc(100vw * (11.23 / 395));
    padding-top: calc(100vw * (11 / 395));
  }
  .title1,
  .title2 {
    width: calc(100vw * (136 / 395));
    height: calc(100vw * (18 / 395));
    font-size: calc(100vw * (16 / 395));
  }
  .desc {
    width: calc(100vw * (130 / 395));
    height: calc(100vw * (80 / 395));
    margin-top: calc(100vw * (2 / 395));
    margin-bottom: calc(100vw * (7.42 / 395));
    font-size: calc(100vw * (10 / 395));
  }
  button {
    position: relative;
    padding-top: calc(100vw * (7 / 395));
    padding-bottom: calc(100vw * (7 / 395));
    padding-left: calc(100vw * (14 / 395));
    padding-right: calc(100vw * (14 / 395));
    font-size: calc(100vw * (7.18 / 395));
  }
}
