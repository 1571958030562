@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
.wrapper {
  width: calc(100vw * (452 / 1920));
  height: calc(100vw * (470 / 1920));
  padding-top: calc(100vw * (52 / 1920));
  padding-bottom: calc(100vw * (52 / 1920));
  padding-left: calc(100vw * (51 / 1920));
  padding-right: calc(100vw * (51 / 1920));
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(100vw * (10 / 1920));
  border-radius: calc(100vw * (40 / 1920));
  border: 1px solid #f6f6f6;
  background: #fff;
  box-shadow: 1px 15px 23px 0px rgba(229, 229, 229, 0.25);
}
.content {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: calc(100vw * (38 / 1920));
  position: relative;
  width: calc(100vw * (350 / 1920));
}

.content .icon {
  width: calc(100vw * (116 / 1920));
  height: calc(100vw * (116 / 1920));
}

.content .contentTitle {
  color: #242331;
  font-family: Nunito;
  font-size: calc(100vw * (30 / 1920));
  font-style: normal;
  font-weight: 700;
}
.content .description {
  width: calc(100vw * (350 / 1920));
  color: #797979;
  font-family: Nunito;
  font-size: calc(100vw * (22 / 1920));
  font-style: normal;
  font-weight: 400;
}

@media (max-width: 500px) {
  .wrapper {
    width: calc(100vw * (175 / 395));
    height: calc(100vw * (194 / 395));
    padding-top: calc(100vw * (20 / 395));
    padding-bottom: calc(100vw * (20 / 395));
    padding-left: calc(100vw * (19 / 395));
    padding-right: calc(100vw * (19 / 395));
    gap: calc(100vw * (14 / 395));
    border-radius: calc(100vw * (15 / 395));
    border: 0.38px solid #f6f6f6;
    background: #fff;
    box-shadow: 0.388px 5.817px 8.92px 0px rgba(229, 229, 229, 0.25);
  }
  .content {
    gap: calc(100vw * (14 / 395));
    width: calc(100vw * (135 / 395));
  }
  
  .content .icon {
    width: calc(100vw * (45 / 395));
    height: calc(100vw * (45 / 395));
  }
  
  .content .contentTitle {
    font-size: calc(100vw * (12 / 395));
  }
  .content .description {
    width: calc(100vw * (135 / 395));
    font-size: calc(100vw * (10 / 395));
  }
  }

