@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
.footer {
  background-color: #fff;
  color: #000;
  padding-top: calc(100vw * (20 / 1920));
  padding-bottom: calc(100vw * (20 / 1920));
  text-align: center;
}
.line{
    width: calc(100vw * (1400 / 1920));
    height: calc(100vw * (1 / 1920));
    margin-bottom: calc(100vw * (25 / 1920));
    margin-left: calc(100vw * (261 / 1920));
    margin-right: calc(100vw * (261 / 1920));
    background: #E3E3E3;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: calc(100vw * (10 / 1920));
  margin-left: calc(100vw * (261 / 1920));
  margin-right: calc(100vw * (261 / 1920));
}

.footerContent p {
  margin: 0;
  color: #797979;
  font-family: Nunito;
  font-size: calc(100vw * (22 / 1920));
  font-style: normal;
  font-weight: 400;
  line-height: 157.4%;
}

.socialIcons {
  display: flex;
  gap: calc(100vw * (15 / 1920));
}

.socialIcons a {
  color: #242331;
  font-size: calc(100vw * (18 / 1920));
  transition: color 0.3s ease;
}

.socialIcons a:hover {
  color: #555;
}

@media (max-width: 500px) {
    .footer {
        padding-top: calc(100vw * (20 / 395));
        padding-bottom: calc(100vw * (20 / 395));
      }
      .line{
          width: calc(100vw * (360 / 395));
          height: calc(100vw * (.5 / 395));
          margin-bottom: calc(100vw * (7 / 395));
          margin-left: calc(100vw * (11 / 395));
          margin-right: calc(100vw * (21 / 395));
          background: #E3E3E3;
      }
      
      .footerContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: calc(100vw * (43 / 395));
        margin-left: calc(100vw * (11 / 395));
        margin-right: calc(100vw * (21 / 395));
      }
      
      .footerContent p {
        font-size: calc(100vw * (10 / 395));
      }
      
      .socialIcons {
        display: flex;
        gap: calc(100vw * (10 / 395));
      }
      
      .socialIcons a {
        color: #242331;
        font-size: calc(100vw * (9.6 / 395));
        transition: color 0.3s ease;
      }
      
      .socialIcons a:hover {
        color: #555;
      }
}