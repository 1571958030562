@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
.section1 {
  position: relative;
  width: calc(100vw * (1920 / 1920));
  padding-bottom:calc(100vw * (100 / 1920));
  height: auto;
  /* border: solid; */
}
.section1Header {
  position: relative;
  z-index: 3;
}
.circle1 {
  position: absolute;
  width: calc(100vw * (1436 / 1920));
  height: calc(100vw * (1432 / 1920));
  top: calc(100vw * (-824 / 1920));
  left: calc(100vw * (-832 / 1920));
  z-index: 1;
}
.circle2 {
  position: absolute;
  width: calc(100vw * (1658 / 1920));
  height: calc(100vw * (1653 / 1920));
  top: calc(100vw * (-854 / 1920));
  left: calc(100vw * (1050 / 1920));
  z-index: 1;
}
.circle3 {
  position: absolute;
  width: calc(100vw * (1158 / 1920));
  height: calc(100vw * (1155 / 1920));
  top: calc(100vw * (-604 / 1920));
  left: calc(100vw * (1331 / 1920));
  z-index: 1;
}

.center {
  margin-top: calc(100vw * (87 / 1920));
  margin-bottom: calc(100vw * (110 / 1920));
  margin-left: calc(100vw * (333 / 1920));
  margin-right: calc(100vw * (336 / 1920));
  display: flex;
  width: calc(100vw * (1251 / 1920));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: calc(100vw * (20 / 1920));
}
.center .title {
  font-size: calc(100vw * (75 / 1920));
  color: #242331;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}
.center .description {
  font-size: calc(100vw * (25 / 1920));
  color: #797979;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  line-height: 157.4%;
}

.cardContainer {
  width: calc(100vw * (1401 / 1920));
  margin-left: calc(100vw * (260 / 1920));
  margin-right: calc(100vw * (259 / 1920));
  display: flex;
  justify-content: space-between;
  gap: calc(100vw * (47 / 1920));
  /* border: solid; */
}

.card1 {
  width: calc(100vw * (447 / 1920));
  height: fit-content;
  padding-top: calc(100vw * (54 / 1920));
  padding-bottom: calc(100vw * (43 / 1920));
  border-radius: calc(100vw * (20 / 1920));
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9f9f9;
}
.card1 .cardTitle {
  margin-bottom: calc(100vw * (35 / 1920)) !important;
  color: #242331;
  font-family: Nunito;
  font-size: calc(100vw * (40 / 1920));
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}
.card1 .line1 {
  width: calc(100vw * (378 / 1920));
  height: calc(100vw * (1 / 1920));
  background: #d3d3d3;
}
.card1 .cardTitle2 {
  margin-top: calc(100vw * (23 / 1920)) !important;
  margin-bottom: calc(100vw * (23 / 1920)) !important;
  color: #242331;
  font-family: Nunito;
  font-size: calc(100vw * (40 / 1920));
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}
.card1 .line2 {
  width: calc(100vw * (378 / 1920));
  height: calc(100vw * (1 / 1920));
  /* margin-bottom: calc(100vw * (43 / 1920)) !important; */
  background: #d3d3d3;
}
.card1 .cardDescription {
  margin-top: calc(100vw * (23 / 1920)) !important;
  color: #797979;
  text-align: center;
  font-family: Nunito;
  font-size: calc(100vw * (22 / 1920));
  font-style: normal;
  font-weight: 400;
  line-height: 157.4%;
}
.card1 button {
  margin-top: calc(100vw * (35 / 1920)) !important;
  display: flex;
  padding-top: calc(100vw * (25 / 1920));
  padding-bottom: calc(100vw * (25 / 1920));
  padding-left: calc(100vw * (51 / 1920));
  padding-right: calc(100vw * (51 / 1920));
  justify-content: center;
  align-items: center;
  gap: calc(100vw * (10 / 1920));
  border-radius: calc(100vw * (39 / 1920));
  border: 1px solid #d3d3d3;
  background-color: transparent;
}
.card1 button span {
  color: #242331;
  font-family: Nunito;
  font-size: calc(100vw * (18 / 1920));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.btn{
  transform: scale(1);
  transition: transform 0.3s;
}
.btn:hover{
  transform: scale(0.9);
}


.card2 {
  width: calc(100vw * (447 / 1920));
  height: fit-content;
  padding-top: calc(100vw * (54 / 1920));
  padding-bottom: calc(100vw * (43 / 1920));
  border-radius: calc(100vw * (20 / 1920));
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #6c5dd3;
}
.card2 .titleContainer {
  display: flex;
  align-items: center;
  gap: calc(100vw * (100 / 1920));
  margin-bottom: calc(100vw * (35 / 1920)) !important;
  /* border: solid; */
}
.card2 .titleContainer .cardTitle {
  color: #fff;
  font-family: Nunito;
  font-size: calc(100vw * (40 / 1920));
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
  /* border: solid; */
}
.card2 .titleContainer .titleButton {
  display: flex;
  padding-top: calc(100vw * (16 / 1920));
  padding-bottom: calc(100vw * (16 / 1920));
  padding-left: calc(100vw * (21 / 1920));
  padding-right: calc(100vw * (21 / 1920));
  justify-content: center;
  align-items: center;
  gap: calc(100vw * (10 / 1920));
  border-radius: calc(100vw * (39 / 1920));
  background: #efecff;
}
.card2 .titleContainer .titleButton span {
  color: #5236ff;
  font-family: Nunito;
  font-size: calc(100vw * (18 / 1920));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.card2 .line1 {
  width: calc(100vw * (378 / 1920));
  height: calc(100vw * (1 / 1920));
  background: #d3d3d3;
}

.card2 .priceContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(100vw * (29 / 1920));
  margin-top: calc(100vw * (23 / 1920)) !important;
}
.card2 .priceContainer .cardTitle {
  color: #fff;
  font-family: Nunito;
  font-size: calc(100vw * (40 / 1920));
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
  /* border: solid; */
}

.card2 .line2 {
  width: calc(100vw * (378 / 1920));
  height: calc(100vw * (1 / 1920));
  /* margin-bottom: calc(100vw * (43 / 1920)) !important; */
  background: #d3d3d3;
}
.card2 .plusIcon{
  color: #fff;
  margin-top: calc(100vw * (10 / 1920)) !important;
}
.card2 .cardDescription {
  margin-top: calc(100vw * (23 / 1920)) !important;
  color: #FFF;
  text-align: center;
  font-family: Nunito;
  font-size: calc(100vw * (20 / 1920));
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.card2 button {
  margin-top: calc(100vw * (43 / 1920)) !important;
  display: flex;
  padding-top: calc(100vw * (25 / 1920));
  padding-bottom: calc(100vw * (25 / 1920));
  padding-left: calc(100vw * (51 / 1920));
  padding-right: calc(100vw * (51 / 1920));
  justify-content: center;
  align-items: center;
  gap: calc(100vw * (10 / 1920));
  border-radius: calc(100vw * (39 / 1920));
  background-color: #FFF;
}
.card2 button span {
  color: #242331;
  font-family: Nunito;
  font-size: calc(100vw * (18 / 1920));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.card3 {
  width: calc(100vw * (447 / 1920));
  height: fit-content;
  padding-top: calc(100vw * (54 / 1920));
  padding-bottom: calc(100vw * (43 / 1920));
  border-radius: calc(100vw * (20 / 1920));
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9f9f9;
  z-index: 5;
}
.card3 .cardTitle {
  margin-bottom: calc(100vw * (35 / 1920)) !important;
  margin-left: calc(100vw * (37 / 1920)) !important;
  color: #242331;
  font-family: Nunito;
  font-size: calc(100vw * (40 / 1920));
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
  align-self: flex-start;
}
.card3 .line1 {
  width: calc(100vw * (378 / 1920));
  height: calc(100vw * (1 / 1920));
  background: #d3d3d3;
}
.card3 .cardTitle2 {
  margin-top: calc(100vw * (23 / 1920)) !important;
  margin-bottom: calc(100vw * (23 / 1920)) !important;
  color: #242331;
  font-family: Nunito;
  font-size: calc(100vw * (40 / 1920));
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}
.card3 .line2 {
  width: calc(100vw * (378 / 1920));
  height: calc(100vw * (1 / 1920));
  background: #d3d3d3;
}
.card3 .cardDescription {
  margin-top: calc(100vw * (23 / 1920)) !important;
  color: #797979;
  text-align: center;
  font-family: Nunito;
  font-size: calc(100vw * (22 / 1920));
  font-style: normal;
  font-weight: 400;
  line-height: 157.4%;
}
.card3 button {
  display: flex;
  margin-top: calc(100vw * (35 / 1920)) !important;
  padding-top: calc(100vw * (25 / 1920));
  padding-bottom: calc(100vw * (25 / 1920));
  padding-left: calc(100vw * (51 / 1920));
  padding-right: calc(100vw * (51 / 1920));
  justify-content: center;
  align-items: center;
  gap: calc(100vw * (10 / 1920));
  border-radius: calc(100vw * (39 / 1920));
  border: 1px solid #d3d3d3;
  background-color: transparent;
}
.card3 button span {
  color: #242331;
  font-family: Nunito;
  font-size: calc(100vw * (18 / 1920));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 500px) {
  .section1 {
    position: relative;
    width: calc(100vw * (395 / 395));
    height: auto;
  }

  .center {
    margin-top: calc(100vw * (40 / 395));
    margin-bottom: calc(100vw * (40 / 395));
    margin-left: calc(100vw * (47 / 395));
    margin-right: calc(100vw * (131 / 395));
    width: calc(100vw * (297 / 395));
    gap: calc(100vw * (10 / 395));
  }
  .center .title {
    font-size: calc(100vw * (16 / 395));
  }
  .center .description {
    font-size: calc(100vw * (10 / 395));
  }

  .cardContainer {
    width: calc(100vw * (211 / 395));
    margin-left: calc(100vw * (91 / 395));
    margin-right: calc(100vw * (91 / 395));
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: calc(100vw * (24 / 395));
    /* border: solid; */
  }
  
  .card1 {
    width: calc(100vw * (211 / 395));
    height: fit-content;
    padding-top: calc(100vw * (25 / 395));
    padding-bottom: calc(100vw * (15 / 395));
    border-radius: calc(100vw * (10 / 395));
  }
  .card1 .cardTitle {
    margin-bottom: calc(100vw * (16 / 395)) !important;
    margin-left: calc(100vw * (16 / 395)) !important;
    font-size: calc(100vw * (16 / 395));
    align-self: flex-start;
  }
  .card1 .line1 {
    width: calc(100vw * (178 / 395));
    height: calc(100vw * (0.5 / 395));
  }
  .card1 .cardTitle2 {
    margin-top: calc(100vw * (11 / 395)) !important;
    margin-bottom: calc(100vw * (11 / 395)) !important;
    font-size: calc(100vw * (10 / 395));
  }
  .card1 .line2 {
    width: calc(100vw * (178 / 395));
    height: calc(100vw * (0.5 / 395));
  }
  .card1 .cardDescription {
    margin-top: calc(100vw * (15 / 395)) !important;
    font-size: calc(100vw * (10 / 395));
  }
  .card1 button {
    margin-top: calc(100vw * (20 / 395)) !important;
    padding-top: calc(100vw * (12 / 395));
    padding-bottom: calc(100vw * (12 / 395));
    padding-left: calc(100vw * (24 / 395));
    padding-right: calc(100vw * (24 / 395));
    gap: calc(100vw * (5 / 395));
    border-radius: calc(100vw * (18 / 395));
    border: 0.5px solid #d3d3d3;
  }
  .card1 button span {
    font-size: calc(100vw * (8.5 / 395));
  }


  .card2 {
    width: calc(100vw * (211 / 395));
    height: fit-content;
    padding-top: calc(100vw * (25 / 395));
    padding-bottom: calc(100vw * (14 / 395));
    border-radius: calc(100vw * (10 / 395));
  }
  .card2 .titleContainer {
    display: flex;
    align-items: center;
    gap: calc(100vw * (60 / 395));
    margin-bottom: calc(100vw * (16 / 395)) !important;
    /* border: solid; */
  }
  .card2 .titleContainer .cardTitle {
    color: #fff;
    font-size: calc(100vw * (16 / 395));
  }
  .card2 .titleContainer .titleButton {
    display: flex;
    padding-top: calc(100vw * (7.5 / 395));
    padding-bottom: calc(100vw * (7.5 / 395));
    padding-left: calc(100vw * (10 / 395));
    padding-right: calc(100vw * (10 / 395));
    justify-content: center;
    align-items: center;
    gap: calc(100vw * (4.7 / 395));
    border-radius: calc(100vw * (18 / 395));
  }
  .card2 .titleContainer .titleButton span {
    font-size: calc(100vw * (8.5 / 395));
  }
  .card2 .line1 {
    width: calc(100vw * (178 / 395));
    height: calc(100vw * (0.5 / 395));
    background: #6950FF;
  }
  
  .card2 .priceContainer {
    gap: calc(100vw * (10 / 395));
    margin-top: calc(100vw * (10 / 395)) !important;
  }
  .card2 .priceContainer .cardTitle {
    font-size: calc(100vw * (19 / 395));
  }
  
  .card2 .line2 {
    width: calc(100vw * (178 / 395));
    height: calc(100vw * (0.5 / 395));
    background: #6950FF;
  }
  .card2 .plusIcon{
    color: #fff;
    margin-top: calc(100vw * (10 / 395)) !important;
  }
  .card2 .cardDescription {
    margin-top: calc(100vw * (15 / 395)) !important;
    font-size: calc(100vw * (10 / 395));
  }
  .card2 button {
    margin-top: calc(100vw * (20 / 395)) !important;
    padding-top: calc(100vw * (12 / 395));
    padding-bottom: calc(100vw * (12 / 395));
    padding-left: calc(100vw * (24 / 395));
    padding-right: calc(100vw * (24 / 395));
    gap: calc(100vw * (4.7 / 395));
    border-radius: calc(100vw * (18.4 / 395));
  }
  .card2 button span {
    font-size: calc(100vw * (8.5 / 395));
  }

  .card3 {
    width: calc(100vw * (211 / 395));
    height: fit-content;
    padding-top: calc(100vw * (25 / 395));
    padding-bottom: calc(100vw * (15 / 395));
    border-radius: calc(100vw * (10 / 395));
    margin-bottom: calc(100vw * (15 / 395));
  }
  .card3 .cardTitle {
    margin-bottom: calc(100vw * (16 / 395)) !important;
    margin-left: calc(100vw * (16 / 395)) !important;
    font-size: calc(100vw * (16 / 395));
    align-self: flex-start;
  }
  .card3 .line1 {
    width: calc(100vw * (178 / 395));
    height: calc(100vw * (0.5 / 395));
  }
  .card3 .cardTitle2 {
    margin-top: calc(100vw * (11 / 395)) !important;
    margin-bottom: calc(100vw * (11 / 395)) !important;
    font-size: calc(100vw * (10 / 395));
  }
  .card3 .line2 {
    width: calc(100vw * (178 / 395));
    height: calc(100vw * (0.5 / 395));
  }
  .card3 .cardDescription {
    margin-top: calc(100vw * (15 / 395)) !important;
    font-size: calc(100vw * (10 / 395));
  }
  .card3 button {
    margin-top: calc(100vw * (20 / 395)) !important;
    padding-top: calc(100vw * (12 / 395));
    padding-bottom: calc(100vw * (12 / 395));
    padding-left: calc(100vw * (24 / 395));
    padding-right: calc(100vw * (24 / 395));
    gap: calc(100vw * (5 / 395));
    border-radius: calc(100vw * (18 / 395));
    border: 0.5px solid #d3d3d3;
  }
  .card3 button span {
    font-size: calc(100vw * (8.5 / 395));
  }
  
}
