@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
.section5 {
  position: relative;
}
.wrapper {
  position: relative;
  width: calc(100vw * (1920 / 1920));
  height: calc(100vw * (727 / 1920));
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leftImgWrapper {
  position: relative;
  width: calc(100vw * (368 / 1920));
  height: calc(100vw * (526 / 1920));
  /* border: solid; */
}
.leftImgWrapper img {
  width: 100%;
  height: 100%;
}
.rightImgWrapper {
  position: relative;
  width: calc(100vw * (368 / 1920));
  height: calc(100vw * (526 / 1920));
  left: calc(100vw * (39 / 1920));
  /* border: solid; */
}
.rightImgWrapper img {
  height: 100%;
}
.counter {
  position: absolute;
  display: flex;
  width: calc(100vw * (1400 / 1920));
  padding-top: calc(100vw * (93 / 1920));
  padding-bottom: calc(100vw * (93 / 1920));
  padding-left: calc(100vw * (67 / 1920));
  padding-right: calc(100vw * (67 / 1920));
  margin-left: calc(100vw * (260 / 1920));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: calc(100vw * (10 / 1920));
  border-radius: calc(100vw * (50 / 1920));
  background: #f9f9f9;
}
.content {
  display: flex;
  width: calc(100vw * (1266 / 1920));
  justify-content: center;
  align-items: center;
  gap: calc(100vw * (135 / 1920));
}
.box {
  display: flex;
  width: calc(100vw * (271 / 1920));
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title {
  display: flex;
  width: calc(100vw * (271 / 1920));
  justify-content: center;
  align-items: center;
}
.title1 {
  color: #242331;
  font-family: Nunito;
  font-size: calc(100vw * (75 / 1920));
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}
.title2 {
  color: #6c5dd3;
  font-family: Nunito;
  font-size: calc(100vw * (75 / 1920));
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}
.desc {
  color: #6c5dd3;
  font-family: Nunito;
  font-size: calc(100vw * (25 / 1920));
  font-style: normal;
  font-weight: 700;
  line-height: 157.4%;
}
.box2 .title2, .box3 .title2{
  margin-left: calc(100vw * (10 / 1920)) !important;
}
@media (max-width: 500px) {
  .wrapper {
    position: relative;
    width: calc(100vw * (395 / 395));
    height: calc(100vw * (180 / 395));
  }
  .leftImgWrapper {
    position: relative;
    width: calc(100vw * (91 / 395));
    height: calc(100vw * (130 / 395));
    right: calc(100vw * (43 / 395));
    /* border: solid; */
  }
  .leftImgWrapper img {
    width: 100%;
    height: 100%;
  }
  .rightImgWrapper {
    position: relative;
    width: calc(100vw * (91 / 395));
    height: calc(100vw * (130 / 395));
    left: calc(100vw * (50 / 395));
    /* border: solid; */
  }
  .rightImgWrapper img {
    height: 100%;
  }
  .counter {
    position: absolute;
    display: flex;
    width: calc(100vw * (347 / 395));
    height: calc(100vw * (78 / 395));
    padding-top: calc(100vw * (23 / 395));
    padding-bottom: calc(100vw * (23 / 395));
    padding-left: calc(100vw * (16 / 395));
    padding-right: calc(100vw * (16 / 395));
    margin-left: calc(100vw * (30 / 395));
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: calc(100vw * (2 / 395));
    border-radius: calc(100vw * (12 / 395));
    background: #f9f9f9;
  }
  .content {
    display: flex;
    width: calc(100vw * (300 / 395));
    justify-content: center;
    align-items: center;
    gap: calc(100vw * (35 / 395));
    /* border: solid; */
  }
  .box {
    display: flex;
    width: calc(100vw * (67 / 395));
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* border: solid; */
  }
  .title {
    display: flex;
    width: calc(100vw * (67 / 395));
    justify-content: flex-start;
    align-items: center;
    /* border: solid; */
  }
  .title1 {
    color: #242331;
    font-family: Nunito;
    font-size: calc(100vw * (16 / 395));
    font-style: normal;
    font-weight: 800;
    line-height: 157%;
  }
  .title2 {
    color: #6c5dd3;
    font-family: Nunito;
    font-size: calc(100vw * (16 / 395));
    font-style: normal;
    font-weight: 800;
    line-height: 157%;
  }
  .desc {
    align-items: center;
    color: #6c5dd3;
    font-family: Nunito;
    font-size: calc(100vw * (10 / 395));
    font-style: normal;
    font-weight: 700;
    line-height: 157.4%;
  }
  .box2 .title2, .box3 .title2{
    margin-left: calc(100vw * (5 / 395));
  }
  .box1 .desc{
    margin-left: calc(100vw * (12 / 395)) !important;
  }
}
