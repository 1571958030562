@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
.section4 {
  position: relative;
}
.wrapper {
  position: relative;
  width: calc(100vw * (1400 / 1920));
  height: calc(100vw * (727 / 1920));
  margin-left: calc(100vw * (276 / 1920));
  margin-right: calc(100vw * (244 / 1920));
  display: flex;
  flex-direction: column;
  align-items: center;
}
.background {
  position: absolute;
  width: calc(100vw * (368 / 1920));
  height: calc(100vw * (522 / 1920));
  top: calc(100vw * (205 / 1920));
  z-index: -1;
  margin: auto;
}
.background .img1 {
  width: 100%;
  height: 100%;
}
.background2 {
  position: absolute;
  width: calc(100vw * (96 / 1920));
  height: calc(100vw * (96 / 1920));
  top: calc(100vw * (420 / 1920));
  z-index: -1;
  margin: auto;
}
.background2 img {
  width: 100%;
  height: 100%;
}
.properties {
  display: flex;
  padding-top: calc(100vw * (25 / 1920));
  padding-bottom: calc(100vw * (25 / 1920));
  padding-left: calc(100vw * (34 / 1920));
  padding-right: calc(100vw * (34 / 1920));
  margin-bottom: calc(100vw * (31 / 1920));
  justify-content: center;
  align-items: center;
  gap: calc(100vw * (10 / 1920));
  border-radius: calc(100vw * (39 / 1920));
  background: #efecff;
}
.properties span {
  color: #6c5dd3;
  font-family: Nunito;
  font-size: calc(100vw * (18 / 1920));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.title {
  color: #242331;
  text-align: center;
  font-family: Nunito;
  font-size: calc(100vw * (50 / 1920));
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}

.propertiesTop,
.propertiesBottom {
  width: calc(100vw * (1400 / 1920));
  height: calc(100vw * (238 / 1920));
  margin-top: calc(100vw * (39 / 1920));
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: calc(100vw * (52 / 1920));
}
.propertiesCard {
  width: calc(100vw * (674 / 1920));
  height: calc(100vw * (260 / 1920));
  padding-top: calc(100vw * (39 / 1920));
  padding-bottom: calc(100vw * (39 / 1920));
  padding-left: calc(100vw * (27 / 1920));
  padding-right: calc(100vw * (27 / 1920));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: calc(100vw * (10 / 1920));
  border-radius: calc(100vw * (30 / 1920));
  background: #f9f9f9;
}
.content {
  display: flex;
  width: calc(100vw * (620 / 1920));
  align-items: center;
  gap: calc(100vw * (40 / 1920));
}
.imgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100vw * (145 / 1920));
  height: calc(100vw * (190 / 1920));
  flex-shrink: 0;
  border-radius: calc(100vw * (23 / 1920));
  background: #6c5dd3;
}
.imgWrapper img {
  width: calc(100vw * (71 / 1920));
  height: calc(100vw * (71 / 1920));
}
.detail {
  display: flex;
  width: calc(100vw * (451 / 1920));
  flex-direction: column;
  align-items: flex-start;
  gap: calc(100vw * (9 / 1920));
}
.detail .title {
  color: #242331;
  font-family: Nunito;
  font-size: calc(100vw * (30 / 1920));
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}
.detail .desc {
  color: #797979;
  font-family: Nunito;
  font-size: calc(100vw * (22 / 1920));
  font-style: normal;
  font-weight: 400;
  line-height: 157.4%;
}

@media (max-width: 500px) {
  .wrapper {
    position: relative;
    width: calc(100vw * (282 / 395));
    height: auto;
    margin-left: calc(100vw * (83 / 395));
    margin-right: calc(100vw * (83 / 395));
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
  .background, .background2 {
    display: none;
  }
  .properties {
    padding-top: calc(100vw * (10.5 / 395));
    padding-bottom: calc(100vw * (10.5 / 395));
    padding-left: calc(100vw * (14 / 395));
    padding-right: calc(100vw * (14 / 395));
    margin-bottom: calc(100vw * (12 / 395));
    gap: calc(100vw * (4 / 395));
    border-radius: calc(100vw * (16 / 395));
  }
  .properties span {
    font-size: calc(100vw * (10 / 395));
  }
  .title {
    font-size: calc(100vw * (16 / 395));
  }
  
  .propertiesTop,
  .propertiesBottom {
    width: calc(100vw * (282 / 395));
    height: auto;
    margin-top: calc(100vw * (16 / 395));
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: calc(100vw * (16 / 395));
  }
  .propertiesCard {
    width: calc(100vw * (282 / 395));
    height: calc(100vw * (120 / 395));
    padding-top: calc(100vw * (16 / 395));
    padding-bottom: calc(100vw * (16 / 395));
    padding-left: calc(100vw * (11 / 395));
    padding-right: calc(100vw * (11 / 395));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: calc(100vw * (4 / 395));
    border-radius: calc(100vw * (12.5 / 395));
    background: #f9f9f9;
  }
  .content {
    display: flex;
    width: calc(100vw * (259 / 395));
    align-items: center;
    gap: calc(100vw * (17 / 395));
  }
  .imgWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100vw * (60 / 395));
    height: calc(100vw * (80 / 395));
    flex-shrink: 0;
    border-radius: calc(100vw * (9 / 395));
    background: #6c5dd3;
  }
  .imgWrapper img {
    width: calc(100vw * (28 / 395));
    height: calc(100vw * (28 / 395));
  }
  .detail {
    display: flex;
    width: calc(100vw * (189 / 395));
    flex-direction: column;
    align-items: flex-start;
    gap: calc(100vw * (5 / 395));
  }
  .detail .title {
    font-size: calc(100vw * (12 / 395));
  }
  .detail .desc {
    font-size: calc(100vw * (10 / 395));
  }
}
