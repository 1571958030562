@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
.section6 {
  position: relative;
}
.wrapper {
  position: relative;
  width: calc(100vw * (1920 / 1920));
  height: calc(100vw * (727 / 1920));
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.title {
  color: #6c5dd3;
  text-align: center;
  font-family: Nunito;
  font-size: calc(100vw * (50 / 1920));
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}
.description {
  width: calc(100vw * (936 / 1920));
  margin-top: calc(100vw * (25 / 1920)) !important;
  color: #797979;
  text-align: center;
  font-family: Nunito;
  font-size: calc(100vw * (22 / 1920));
  font-style: normal;
  font-weight: 400;
  line-height: 157.4%;
}

.partners {
  margin-top: calc(100vw * (146 / 1920));
  overflow: hidden;
  position: relative;
  width: 100%;
  height: calc(100vw * (175 / 1920));
  white-space: nowrap; 
}

.marquee {
  display: flex;
  width: max-content;
  position: absolute;
  animation: marquee 800s linear infinite;
}

.track {
  display: flex;
  gap: calc(100vw * (100 / 1920));
  white-space: nowrap;
}

.partner {
  flex-shrink: 0;
}

.partnerImg {
  max-height:calc(100vw * (120 / 1920));
  object-fit: contain;
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    /* transform: translateX(-100%); */
    transform: translateX(-50%);
  }
}
@media (max-width: 500px) {
  .wrapper {
    position: relative;
    width: calc(100vw * (395 / 395));
  }
  .title {
    font-size: calc(100vw * (16 / 395));
  }
  .description {
    width: calc(100vw * (291 / 395));
    margin-top: calc(100vw * (13 / 395)) !important;
    font-size: calc(100vw * (10 / 395));
  }
  .partners{
    height: calc(100vw * (32 / 395));
    margin-top: calc(100vw * (36 / 395));
    gap: calc(100vw * (31 / 395));
  }
  .marquee {
    /* animation: marquee 15s linear infinite; */
  }
  .track {
    gap: calc(100vw * (50 / 395));
  }
}
