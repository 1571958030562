@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
.section3 {
  position: relative;
}
.leftBox {
  position: relative;
  width: calc(100vw * (921 / 1920));
  height: calc(100vw * (750 / 1920));
  margin-left: calc(100vw * (260 / 1920));
  padding-top: calc(100vw * (176 / 1920));
  border-radius: calc(100vw * (50 / 1920));
  background: #f1f1f1;
}
.textContainer {
  width: calc(100vw * (637 / 1920));
  margin-left: calc(100vw * (51 / 1920));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: calc(100vw * (26 / 1920));
}
.title1 {
  width: calc(100vw * (599 / 1920));
  color: #6c5dd3;
  font-family: Nunito;
  font-size: calc(100vw * (50 / 1920));
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}
.title2 {
  width: calc(100vw * (637 / 1920));
  color: #242331;
  font-size: calc(100vw * (50 / 1920));
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}
.title3 {
  width: calc(100vw * (637 / 1920));
  color: #242331;
  font-size: calc(100vw * (50 / 1920));
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}
.title3 .span1{
  color: #ef7f1a;
}
.descContainer {
  width: calc(100vw * (593 / 1920));
  color: #797979;
  font-family: Nunito;
  font-size: calc(100vw * (22 / 1920));
  font-style: normal;
  font-weight: 400;
  line-height: 157.4%;
}
.imgage1{
  width: calc(100vw * (818 / 1920));
  height: calc(100vw * (529 / 1920));
  border-radius: calc(100vw * (37 / 1920));
  position: absolute;
  top: calc(100vw * (121 / 1920));
  left: calc(100vw * (671 / 1920));
  z-index: 1;
}
.imgage1 img{
  width: 100%;
  height: 100%;
  position: absolute;
}
.imgage2{
  width: calc(100vw * (368 / 1920));
  height: calc(100vw * (750 / 1920));
  border-radius: calc(100vw * (37 / 1920));
  position: absolute;
  left: calc(100vw * (1275 / 1920));
  top: 0;
}
.imgage2 img{
  width: 100%;
  height: 100%;
}

@media (max-width: 500px) {
  .leftBox {
    position: relative;
    width: calc(100vw * (216 / 395));
    height: calc(100vw * (267 / 395));
    margin-left: calc(100vw * (15 / 395));
    padding-top: calc(100vw * (23 / 395));
    border-radius: calc(100vw * (11 / 395));
  }
  .textContainer {
    width: calc(100vw * (168 / 395));
    margin-left: calc(100vw * (15 / 395));
    gap: calc(100vw * (3 / 395));
  }
  .title1 {
    width: calc(100vw * (168 / 395));
    font-size: calc(100vw * (16 / 395));
  }
  .title2 {
    width: calc(100vw * (168 / 395));
    font-size: calc(100vw * (16 / 395));
  }
  .title3 {
    width: calc(100vw * (168 / 395));
    font-size: calc(100vw * (16 / 395));
  }
  .descContainer {
    width: calc(100vw * (135 / 395));
    font-size: calc(100vw * (10 / 395));
  }
  .imgage1{
    width: calc(100vw * (184 / 395));
    height: calc(100vw * (118 / 395));
    border-radius: calc(100vw * (12 / 395));
    position: absolute;
    top: calc(100vw * (74 / 395));
    left: calc(100vw * (158 / 395));
    z-index: 1;
  }
  .imgage1 img{
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .imgage2{
    width: calc(100vw * (87 / 395));
    height: calc(100vw * (267 / 395));
    border-radius: calc(100vw * (37 / 395));
    position: absolute;
    left: calc(100vw * (300 / 395));
    top: 0;
  }
  .imgage2 img{
    width: 100%;
    height: 100%;
  }
}
