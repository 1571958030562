@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
.navLinks {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navItemsContainer {
  min-width: calc(100vw * (516 / 1920));
  height: calc(100vw * (75 / 1920));
  border-radius: calc(100vw * (39 / 1920));
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: calc(100vw * (24 / 1920));
  padding-bottom: calc(100vw * (26 / 1920));
  padding-left: calc(100vw * (59 / 1920));
  padding-right: calc(100vw * (36 / 1920));
  margin-right: calc(100vw * (61 / 1920));
}
.navItems {
  width: calc(100vw * (421 / 1920));
  height: calc(100vw * (25 / 1920));
  border-radius: calc(100vw * (39 / 1920));
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navItem {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: calc(100vw * (18 / 1920));
  line-height: normal;
  text-decoration: none;
  color: #242331;
  transition: color 0.3s, transform 0.3s ease;
  text-decoration: none;
}
.navItem:hover{
  color: #f39c12;
  transform: scale(1.1);
}
.activeNavItem {
  color: #6c5dd3;
  font-weight: bold;
}
.navButton {
  min-width: calc(100vw * (218 / 1920));
  height: calc(100vw * (75 / 1920));
  border-radius: calc(100vw * (39 / 1920));
  background-color: #6c5dd3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: calc(100vw * (54 / 1920));
  padding-bottom: calc(100vw * (54 / 1920));
  padding-left: calc(100vw * (25 / 1920));
  padding-right: calc(100vw * (25 / 1920));
  margin-left: calc(100vw * (61 / 1920));
  transition: transform 0.3s;
}
.navButton:hover{
  transform: scale(0.9);
}
.btnText {
  background-color: #6c5dd3;
  color: #fff;
  font-family: Nunito;
  height: calc(100vw * (25 / 1920));
  font-size: calc(100vw * (18 / 1920));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
}

.navItemsContainerMobil {
  display: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hamburgerIcon {
  display: none;
}
.hamburgerIconImg {
  /* display: none; */
  cursor: pointer;
  width: 18px;
  height: 14px;
  position: relative;
  bottom: 3px;
  margin-left: calc(100vw * (13 / 395));
}

.navItemsMobil {
  display: none;
  flex-direction: column;
  gap: calc(100vw * (10.83 / 395));
  position: absolute;
  width: calc(100vw * (161 / 395));
  min-height: calc(100vw * (81.25 / 395));
  padding-left: calc(100vw * (12.04 / 395)) !important;
  padding-top: calc(100vw * (7.52 / 395));
  padding-bottom: calc(100vw * (10.07 / 395));
  top: 25px;
  right: 0;
  background-color: #f4f4f4;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.navItemsMobil.active {
  display: flex; /* Menüyü aç */
}

.navItemMobil {
  color: #737982;
  text-decoration: none;
  font-family: Nunito;
  font-size: calc(100vw * (10 / 395));
  padding-top: calc(100vw * (2 / 395));
  padding-bottom: calc(100vw * (2 / 395));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: background-color 0.3s;
}

.navItemMobil:hover {
  background-color: #f0f0f0; /* Üzerine gelindiğinde arka plan rengi */
}
@media (max-width: 500px) {
  .navItemsContainer {
    display: none;
  }
  .hamburgerIcon,
  .navItemsContainerMobil {
    display: block;
  }
  .navButton {
    width: calc(100vw * (77 / 395));
    height: calc(100vw * (20 / 395));
    border-radius: calc(100vw * (18.97 / 395));
    background-color: #6c5dd3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: calc(100vw * (7.04 / 395));
    padding-bottom: calc(100vw * (7.04 / 395));
    padding-left: calc(100vw * (14.36 / 395));
    padding-right: calc(100vw * (14.36 / 395));
    margin-left: calc(100vw * (68.62 / 1920));
  }
  .btnText {
    background-color: #6c5dd3;
    color: #fff;
    font-family: Nunito;
    height: calc(100vw * (14 / 395));
    font-size: calc(100vw * (10 / 395));
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
  }
  .LogoImg {
    width: calc(100vw * (166.37 / 395));
    height: calc(100vw * (20.72 / 395));
  }
}
