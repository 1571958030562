@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
.section2 {
  position: relative;
  width: calc(100vw * (1920 / 1920));
  height: fit-content;
}

.wrapper {
  display: flex;
  width: calc(100vw * (1400 / 1920));
  margin-left: calc(100vw * (260 / 1920));
  margin-right: calc(100vw * (260 / 1920));
  flex-direction: column;
  align-items: center;
  gap: calc(100vw * (30 / 1920));
}
.title {
  font-size: calc(100vw * (40 / 1920));
  color: #242331;
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  line-height: 123%;
}
.card {
  width: calc(100vw * (1400 / 1920));
  height: fit-content;
  /* padding-top: calc(100vw * (53 / 1920)); */
  border-radius: calc(100vw * (50 / 1920));
  background: #f9f9f9;
  margin-bottom:calc(100vw * (50 / 1920));
}
.titleContainer {
  display: flex;
  align-items: center;
  padding-top: calc(100vw * (53 / 1920));
  padding-left: calc(100vw * (65 / 1920));
  padding-right: calc(100vw * (61 / 1920));
  padding-bottom: calc(100vw * (53 / 1920));
  border-top-left-radius:calc(100vw * (50 / 1920));
  border-top-right-radius:calc(100vw * (50 / 1920));
  background-color: #6c5dd3;
}
.properties {
  width: calc(100vw * (459 / 1920));
}
.properties .title {
  color: #fff;
  font-family: Nunito;
  font-size: calc(100vw * (40 / 1920));
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}
.platformPlans {
  gap: calc(100vw * (40 / 1920));
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  text-align: center;
  width: calc(100vw * (880 / 1920));
}
.free,
.standart,
.premium, .vip {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(100vw * (7 / 1920));
}
.free .title,
.standart .title,
.premium .title,
.vip .title{
  color: #fff;
  font-family: Nunito;
  font-size: calc(100vw * (40 / 1920));
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}
.free .price,
.standart .price,
.premium .price,
.vip .price {
  color: #fff;
  font-family: Nunito;
  font-size: calc(100vw * (20 / 1920));
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
}
.line {
  width: calc(100vw * (1400 / 1920));
  height: calc(100vw * (1 / 1920));
  background: #d3d3d3;
}
.mainRow {
  height: calc(100vw * (64 / 1920));
  display: flex;
  align-items: center;
  padding-left: calc(100vw * (65 / 1920));
  padding-right: calc(100vw * (61 / 1920));
  /* border: solid; */
}
.evenRow {
  background-color: #ffffff;
}

.oddRow {
  background-color: #f9f9f9;
}

.propertiesColumn {
  width: calc(100vw * (459 / 1920));
}
.propertiesTitle1 {
  color: #5236ff;
  font-family: Nunito;
  font-size: calc(100vw * (20 / 1920));
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}
.propertiesTitle2 {
  color: #797979;
  font-family: Nunito;
  font-size: calc(100vw * (20 / 1920));
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.platformPlansColumn {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  text-align: center;
  width: calc(100vw * (880 / 1920));
  gap: calc(100vw * (40 / 1920));
}
.freeColumn, .standartColumn, .premiumColumn, .vipColumn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vw * (44 / 1920));
}

.freeColumn svg,
.standartColumn svg,
.premiumColumn svg,
.vipColumn svg {
  width: calc(100vw * (28 / 1920));
  height: calc(100vw * (28 / 1920));
  z-index: 5;
}
.tooltipContainer {
  position: relative;
  display: inline-block;
}

.tooltipText {
  visibility: hidden;
  width: calc(100vw * (160 / 1920));
  font-size: calc(100vw * (20 / 1920));
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: calc(100vw * (15 / 1920));
  padding: calc(100vw * (5 / 1920));
  position: absolute;
  z-index: 1;
  bottom: 70%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltipContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}
.dipNot{
  color: #797979;
  font-size: calc(100vw * (20 / 1920));
  font-family: "Nunito", sans-serif;
  margin-left: calc(100vw * (65 / 1920)) !important;
  margin-top: calc(100vw * (20 / 1920)) !important;
  line-height: 1.5;
}

.asterisk {
  color: #f39c12;
  /* font-size: 1.2rem; */
  margin-right: 5px;
  font-weight: bold;
}
.btnContainer {
  margin-top: calc(100vw * (50 / 1920));
  margin-bottom: calc(100vw * (40 / 1920));
  margin-left: calc(100vw * (496 / 1920));
  margin-right: calc(100vw * (61 / 1920));
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  text-align: center;
  gap: calc(100vw * (40 / 1920));
}
.btn {
  display: inline-flex;
  padding-right: calc(100vw * (51 / 1920));
  padding-left: calc(100vw * (51 / 1920));
  padding-top: calc(100vw * (25 / 1920));
  padding-bottom: calc(100vw * (25 / 1920));
  justify-content: center;
  align-items: center;
  border-radius: calc(100vw * (39 / 1920));
  border: 1px solid #d3d3d3;
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  cursor: pointer;
}

.btn span{
  color: #242331;
  font-family: Nunito;
  font-size: calc(100vw * (18 / 1920));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: color 0.3s, transform 0.3s;
}

.btn:hover {
  background-color: #6c5dd3;
}

.btn:hover span {
  color: #fff;
  transform: scale(1.3);
}

@media (max-width: 500px) {
  .section2 {
    position: relative;
    width: calc(100vw * (395 / 395));
    height: auto;
  }

  .wrapper {
    width: calc(100vw * (337 / 395));
    margin-left: calc(100vw * (28 / 395));
    margin-right: calc(100vw * (28 / 395));
    gap: calc(100vw * (20 / 395));
  }
  .title {
    font-size: calc(100vw * (16 / 395));
  }
  .card {
    width: calc(100vw * (337 / 395));
    height: fit-content;
    /* padding-top: calc(100vw * (18 / 395)); */
    border-radius: calc(100vw * (12.5 / 395));
  }
  .titleContainer {
    padding-top: calc(100vw * (18 / 395));
    padding-bottom: calc(100vw * (10 / 395));
    padding-left: calc(100vw * (23 / 395));
    padding-right: calc(100vw * (22 / 395));
    border-top-left-radius:calc(100vw * (12.5 / 395));
    border-top-right-radius:calc(100vw * (12.5 / 395));
    /* background-color: #6c5dd3; */
  }
  .properties {
    width: calc(100vw * (100 / 395));
  }
  .properties .title {
    font-size: calc(100vw * (10 / 395));
  }
  .platformPlans {
    gap: calc(100vw * (1 / 395));
    width: calc(100vw * (201 / 395));
  }
  .free,
  .standart,
  .premium, .vip {
    gap: calc(100vw * (1 / 395));
  }
  .free .title,
  .standart .title,
  .premium .title {
    font-size: calc(100vw * (10 / 395));
  }
  .free .title,
  .standart .title,
  .premium .title,
  .vip .title{
    font-size: calc(100vw * (10 / 395));
  }
  .line {
    width: calc(100vw * (337 / 395));
    height: calc(100vw * (0.5 / 395));
    background: #d3d3d3;
  }
  .mainRow {
    height: calc(100vw * (40 / 395));
    padding-left: calc(100vw * (23 / 395));
    padding-right: calc(100vw * (22 / 395));
  }
  .propertiesColumn {
    width: calc(100vw * (100 / 395));
  }
  .propertiesTitle1 {
    font-size: calc(100vw * (10 / 395));
  }
  .propertiesTitle2 {
    font-size: calc(100vw * (10 / 395));
  }
  .platformPlansColumn {
    width: calc(100vw * (201 / 395));
    gap: calc(100vw * (1 / 395));
  }

  .freeColumn, .standartColumn, .premiumColumn, .vipColumn {
    height: calc(100vw * (30 / 395));
  }

  .freeColumn svg,
  .standartColumn svg,
  .premiumColumn svg,
  .vipColumn svg {
    width: calc(100vw * (10 / 395));
    height: calc(100vw * (10 / 395));
  }
  .dipNot{
    color: #797979;
    font-family: "Nunito", sans-serif;
    margin-left: calc(100vw * (23 / 395)) !important;
    margin-top: calc(100vw * (10 / 395)) !important;
    font-size: calc(100vw * (10 / 395));
    line-height: 1.5;
  }
  
  .asterisk {
    color: #f39c12;
    margin-right: 3px;
    font-weight: bold;
  }
  .btnContainer {
    margin-top: calc(100vw * (10 / 395));
    margin-bottom: calc(100vw * (12 / 395));
    margin-left: calc(100vw * (120 / 395));
    margin-right: calc(100vw * (25 / 395));
    gap: calc(100vw * (5 / 395));
  }
  .btn {
    padding-right: calc(100vw * (1 / 395));
    padding-left: calc(100vw * (1 / 395));
    padding-top: calc(100vw * (2 / 395));
    padding-bottom: calc(100vw * (2 / 395));
    border-radius: calc(100vw * (9.7 / 395));
  }
  .btn span{
    font-size: calc(100vw * (7 / 395));
  }
  .btn:hover span {
    color: #fff;
    transform: scale(1.1);
  }
  
}
