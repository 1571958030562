@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
.section2 {
  position: relative;
}
.section2TitleContainer {
  width: calc(100vw * (791 / 1920));
  height: calc(100vw * (55 / 1920));
  margin: auto;
}
.section2TitleContainer .title {
  text-align: center;
  font-family: Nunito;
  font-size: calc(100vw * (50 / 1920));
  font-style: normal;
  font-weight: 800;
}

.title .wrapper{
    color: #242331;
}
.title .span{
    color: #6c5dd3;
}

.contentContainer{
  margin-top: calc(100vw * (38 / 1920));
  margin-bottom: calc(100vw * (38 / 1920));
  padding-left: calc(100vw * (276 / 1920));
  padding-right: calc(100vw * (246 / 1920));
}
.content1{
  margin-bottom: calc(100vw * (36 / 1920));
}
.content2{
  margin-bottom: calc(100vw * (36 / 1920));
}
.content3{
  margin-bottom: calc(100vw * (36 / 1920));
}


@media (max-width: 500px) {
.section2TitleContainer {
  width: calc(100vw * (393 / 395));
  height: calc(100vw * (18 / 395));
}
.section2TitleContainer .title {
  font-size: calc(100vw * (16 / 395));
}
.contentContainer{
  margin-top: calc(100vw * (38 / 395));
  margin-bottom: calc(100vw * (38 / 395));
  padding-left: calc(100vw * (15 / 395));
  padding-right: calc(100vw * (15 / 395));
}
.content1{
  margin-bottom: calc(100vw * (12 / 395));
}
.content2{
  margin-bottom: calc(100vw * (12 / 395));
}
.content3{
  margin-bottom: calc(100vw * (12 / 395));
}
}
