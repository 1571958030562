/* src/components/Header.css */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  z-index: 999;
  margin-left: calc(100vw * (161 / 1920));
  margin-right: calc(100vw * (161 / 1920));
  margin-top: calc(100vw * (72 / 1920));
}
