@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
.section6 {
  position: relative;
}
.wrapper {
  position: relative;
  width: calc(100vw * (1920 / 1920));
  display: flex;
  align-items: flex-start;
  gap: calc(100vw * (149 / 1920));
}
.leftBox {
  width: calc(100vw * (359 / 1920));
  margin-left: calc(100vw * (262 / 1920));
}
.title {
  color: #242331;
  font-family: Nunito;
  font-size: calc(100vw * (50 / 1920));
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}
.subTitle {
  color: #6c5dd3;
  font-family: Nunito;
  font-size: calc(100vw * (30 / 1920));
  margin-top: calc(100vw * (17 / 1920)) !important;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}
.rightBox {
  width: calc(100vw * (922 / 1920));
  display: flex;
  flex-direction: column;
  gap: calc(100vw * (15 / 1920));
}
.questionContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.questionNumber {
  color: #6c5dd3;
  font-family: Nunito;
  font-size: calc(100vw * (30 / 1920));
  margin-right: calc(100vw * (51 / 1920)) !important;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  align-self: flex-start;
}

.questionTitle {
  color: #242331;
  font-family: Nunito;
  width: calc(100vw * (682 / 1920));
  margin-right: calc(100vw * (71 / 1920)) !important;
  font-size: calc(100vw * (30 / 1920));
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  flex-grow: 1; /* Eleman genişliği kadar yer kaplamasını sağlar */
  text-align: left; /* Metni sola yaslar */
  /* border: solid; */
}

.questionLogo {
  align-self: flex-start;
  width: calc(100vw * (17.50 / 1920));
  height: calc(100vw * (17.50 / 1920));
  display: flex;
}
.questionLogo img {
  width: 100%;
  height: 100%;
}

.questionDesc {
  width: calc(100vw * (780 / 1920));
  font-size: calc(100vw * (22 / 1920));
  margin-left: calc(100vw * (90 / 1920)) !important;
  margin-top: calc(100vw * (17 / 1920)) !important;
  color: #797979;
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  line-height: 157.4%;
  text-align: justify;
}

.questionLine {
  width: calc(100vw * (922 / 1920));
  height: calc(100vw * (2 / 1920));
  margin-top: calc(100vw * (40 / 1920)) !important;
  margin-bottom: calc(100vw * (36 / 1920)) !important;
  background: #ececf1;
}
@media (max-width: 500px) {
  .wrapper {
    position: relative;
    width: calc(100vw * (395 / 395));
    gap: calc(100vw * (27 / 395));
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
  .leftBox {
    width: calc(100vw * (209 / 395));
    margin-left: calc(100vw * (92 / 395));
  }
  .title {
    font-size: calc(100vw * (16 / 395));
    text-align: center;
  }
  .subTitle {
    font-size: calc(100vw * (10 / 395));
    margin-top: calc(100vw * (5 / 395)) !important;
    text-align: center;
  }
  .rightBox {
    width: calc(100vw * (393 / 395));
    gap: calc(100vw * (14 / 395));
    padding-left: calc(100vw * (41 / 395));
    padding-right: calc(100vw * (32 / 395));
  }
  
  .questionNumber {
    font-size: calc(100vw * (10 / 395));
    margin-right: calc(100vw * (18 / 395)) !important;
  }
  
  .questionTitle {
    width: calc(100vw * (243 / 395));
    margin-right: calc(100vw * (37 / 395)) !important;
    font-size: calc(100vw * (12 / 395));
  }
  
  .questionLogo {
    width: calc(100vw * (6.26 / 395));
    height: calc(100vw * (6.26 / 395));
  }
  
  .questionDesc {
    width: calc(100vw * (275 / 395));
    font-size: calc(100vw * (10 / 395));
    margin-left: calc(100vw * (30 / 395)) !important;
    margin-top: calc(100vw * (5 / 395)) !important;
  }
  
  .questionLine {
    width: calc(100vw * (329 / 395));
    height: calc(100vw * (0.71 / 395));
    margin-top: calc(100vw * (16 / 395)) !important;
    margin-bottom: calc(100vw * (12 / 395)) !important;
  }
}
