@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
.contentWrapper {
  position: relative;
  width: calc(100vw * (1920 / 1920));
  height: fit-content;
}
.mainTitle {
  font-size: calc(100vw * (75 / 1920));
  color: #242331;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
}
.description {
  width: calc(100vw * (1158 / 1920));
  font-size: calc(100vw * (25 / 1920));
  margin: auto !important;
  margin-top: calc(100vw * (20 / 1920)) !important;
  color: #797979;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  line-height: 157.4%;
  /* border: solid; */
}
.Grid {
  width: calc(100vw * (1280 / 1920));
  margin: auto !important;
  margin-top: calc(100vw * (80 / 1920)) !important;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: calc(100vw * (34 / 1920));
}

.Card {
  margin-top: calc(100vw * (50 / 1920)) !important;
  width: calc(100vw * (623 / 1920));
}

.Image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.title {
  margin-top: calc(100vw * (24 / 1920)) !important;
  color: #242331;
  font-family: Nunito;
  font-size: calc(100vw * (40 / 1920));
  font-style: normal;
  font-weight: 800;
  line-height: 123%;
}

.content {
  color: #797979;
  font-family: Nunito;
  font-size: calc(100vw * (22 / 1920));
  font-style: normal;
  font-weight: 400;
  line-height: 157.4%;
}

@media (max-width: 500px) {
  .contentWrapper {
    position: relative;
    height: fit-content;
  }
  .mainTitle {
    font-size: calc(100vw * (16 / 395));
  }
  .description {
    font-size: calc(100vw * (10 / 395));
  }
  .Grid {
    width: calc(100vw * (224 / 395));
    margin-top: calc(100vw * (0 / 395)) !important;
    margin: auto !important;
    display: grid;
    grid-template-columns: 1fr;
  }

  .Card {
    margin-top: calc(100vw * (30 / 395)) !important;
    width: calc(100vw * (224 / 395));
  }

  .Image {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
  }

  .title {
    margin-top: calc(100vw * (10 / 395)) !important;
    font-size: calc(100vw * (16 / 395));
  }

  .content {
    font-size: calc(100vw * (10 / 395));
  }
}
