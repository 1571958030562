@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
.Student {
  position: relative;
  width: calc(100vw * (1920 / 1920));
  height: calc(100vw * (959 / 1920));
}
.wrapper {
  position: relative;
  width: calc(100vw * (1323 / 1920));
  height: calc(100vw * (727 / 1920));
  margin-left: calc(100vw * (297 / 1920));
  margin-right: calc(100vw * (300 / 1920));
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top {
  display: flex;
  padding-top: calc(100vw * (25 / 1920));
  padding-bottom: calc(100vw * (25 / 1920));
  padding-left: calc(100vw * (34 / 1920));
  padding-right: calc(100vw * (34 / 1920));
  margin-bottom: calc(100vw * (31 / 1920));
  justify-content: center;
  align-items: center;
  gap: calc(100vw * (10 / 1920));
  border-radius: calc(100vw * (39 / 1920));
  background: #efecff;
  /* border: solid; */
}
.top span {
  color: #6c5dd3;
  font-family: Nunito;
  font-size: calc(100vw * (18 / 1920));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.title {
  margin-bottom: calc(100vw * (46 / 1920));
  text-align: center;
  font-family: Nunito;
  font-size: calc(100vw * (50 / 1920));
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
  /* border: solid; */
}
.title1 {
  color: #242331;
}
.title2 {
  color: #6c5dd3;
}

.cardContainer {
  display: flex;
  justify-content: space-between;
  gap: calc(100vw * (47 / 1920));
  /* border: solid; */
}

.card1 {
  width: calc(100vw * (388 / 1920));
  padding-top: calc(100vw * (34 / 1920));
  display: flex;
  flex-direction: column;
  align-items: center;
}
.centerCard{
  width: calc(100vw * (453 / 1920));
  border-radius: calc(100vw * (40 / 1920));
  background: #f9f9f9;
}

.card1 .cardImage {
  width: calc(100vw * (386 / 1920));
  height: calc(100vw * (245 / 1920));
}
.card1 .cardImage img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.card1 .cardTitle {
  margin-top: calc(100vw * (43 / 1920)) !important;
  color: #242331;
  font-family: Nunito;
  font-size: calc(100vw * (30 / 1920));
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}
.card1 .cardDescription {
  margin-top: calc(100vw * (23 / 1920)) !important;
  color: #797979;
  text-align: center;
  font-family: Nunito;
  font-size: calc(100vw * (22 / 1920));
  font-style: normal;
  font-weight: 400;
  line-height: 157.4%;
}

.moreDetail {
  /* display: none; */
  position: absolute;
  width: calc(100vw * (124 / 1920));
  top: calc(100vw * (-50 / 1920));
  right: calc(100vw * (199 / 1920));
  font-size: calc(100vw * (25 / 1920));
  color: #5236ff;
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  cursor: pointer;
  z-index: 10;
}

@media (max-width: 500px) {
  .Student {
    position: relative;
    width: calc(100vw * (395 / 395));
    height: auto;
    top: calc(100vw * (-50 / 395));
  }
  .wrapper {
    position: relative;
    width: calc(100vw * (250 / 395));
    height: auto;
    margin-left: calc(100vw * (70 / 395));
    margin-right: calc(100vw * (156 / 395));
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .top {
    padding-top: calc(100vw * (10.5 / 395));
    padding-bottom: calc(100vw * (10.5 / 395));
    padding-left: calc(100vw * (14 / 395));
    padding-right: calc(100vw * (14 / 395));
    margin-bottom: calc(100vw * (39 / 395));
    gap: calc(100vw * (4 / 395));
    border-radius: calc(100vw * (16 / 395));
  }
  .top span {
    font-size: calc(100vw * (10 / 395));
  }

  .title {
    display: flex;
    flex-direction: column;
    margin-bottom: calc(100vw * (46 / 395));
    font-size: calc(100vw * (16 / 395));
  }

  .cardContainer {
    width: calc(100vw * (250 / 395));
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card1 {
    width: calc(100vw * (214 / 395));
    padding-top: calc(100vw * (1 / 395));
  }
  .centerCard{
    width: calc(100vw * (250 / 395));
    padding-top: calc(100vw * (15 / 395));
    padding-left: calc(100vw * (10 / 395));
    padding-right: calc(100vw * (10 / 395));
    margin-top: calc(100vw * (39 / 395));
    border-radius: calc(100vw * (22 / 395));
  }
  .card1 .cardImage {
    width: calc(100vw * (214 / 395));
    height: calc(100vw * (135 / 395));
  }
  .card1 .cardImage img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .card1 .cardTitle {
    margin-top: calc(100vw * (23 / 395)) !important;
    font-size: calc(100vw * (16 / 395));
  }
  .card1 .cardDescription {
    margin-top: calc(100vw * (12 / 395)) !important;
    font-size: calc(100vw * (10 / 395));
  }

  .moreDetail {
    /* display: none; */
    position: absolute;
    width: calc(100vw * (70 / 395));
    top: calc(100vw * (7 / 395));
    right: calc(100vw * (50 / 395));
    font-size: calc(100vw * (10 / 395));
  }
}
